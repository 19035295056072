.back-to-top {
  position: fixed;
  right: 2.5em;
  bottom: 8.5em;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.back-to-top.show {
  opacity: 1;
  visibility: visible;
}
