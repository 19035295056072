.image-box-card3 {
  opacity: 0;
  animation-duration: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  max-width: 400px;
}

.image-box-card3 .ibc3-lightbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
}

.image-box-card3:hover .ibc3-lightbox {
  opacity: 1;
  visibility: visible;
}

.image-box-card3 .ibc3-inner {
  position: relative;
  margin: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 10px;
}

.image-box-card3 .ibc3-inner span {
  display: inline-block;
}

.image-box-card3 .ibc3-inner span::after {
  content: "/";
  display: inline-block;
  margin: 0 5px;
  font-size: 14px;
}
.image-box-card3 .ibc3-inner span:nth-last-of-type(1)::after {
  display: none;
}
