.about-us-introduct {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.about-us-introduct .aui-item {
  display: flex;
  flex-wrap: wrap;
  padding: 1.25rem;
  position: relative;
}

.about-us-introduct .aui-item .aui-img-wrapper {
  margin-bottom: 2em;
  position: relative;
}

.about-us-introduct h3 {
  font-size: 1.5rem;
  letter-spacing: 2px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 0.5rem;
}

.about-us-introduct .aui-text {
  font-size: 1rem;
  letter-spacing: 2px;
  position: relative;
  margin-top: 1em;
  padding-top: 1em;
  display: inline-block;
}

.about-us-introduct .aui-content.has-title .aui-text::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  background: -webkit-linear-gradient(0deg, #642bd4, #0e91ee);
}

@media (min-width: 600px) {
  .about-us-introduct .aui-item .aui-img-wrapper {
    flex: 0 0 50%;
  }
  .about-us-introduct .aui-item .aui-content {
    flex: 0 0 50%;
    padding: 1.25em;
  }
  .about-us-introduct h3 {
    font-size: 2em;
  }
}

@media (min-width: 992px) {
  .about-us-introduct .aui-item .aui-img-wrapper {
    margin-bottom: 0;
  }

  .about-us-introduct .aui-item {
    display: block;
    padding: 0;
    width: 50%;
  }
  .about-us-introduct .aui-item .aui-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #ffffff;
    padding: 0;
    transition: all 0.8s;
    width: 350px;
  }

  .about-us-introduct .aui-left.large-width {
    width: 35vw;
  }

  .about-us-introduct .aui-right {
    right: 60px;
    text-align: right;
  }

  .about-us-introduct .aui-left {
    left: 60px;
  }

  .about-us-introduct .aui-item .aui-content.aui-hide {
    margin-top: 40px;
    opacity: 0;
  }

  .about-us-introduct .aui-item .aui-content.aui-show {
    margin-top: 0px;
    opacity: 1;
  }
  .about-us-introduct .aui-item .aui-img-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .about-us-introduct h3 {
    font-size: 2.5em;
  }
}

@media (min-width: 1200px) {
  .about-us-introduct .aui-text {
    font-size: 1.3rem;
  }
}
