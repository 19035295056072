.custom-breadcrumb {
  background: #ffffff;
  padding: 15px 0;
  font-size: 14px;
  color: #828282;
  border-bottom: 1px solid #f5f5f5;
}

.custom-breadcrumb span {
  margin-left: 3px;
  margin-right: 3px;
}

.custom-breadcrumb span::after {
  content: ">";
  display: inline-block;
  margin: 0 10px;
}

.custom-breadcrumb span:nth-last-child(1)::after {
  display: none;
}
