.category-box-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
}

.category-box-image .cbi-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  /*   opacity: 0;
  visibility: hidden; */
  transition: all 0.3s ease-in-out;
  /*   font-size: 20px; */
}

/* .category-box-image:hover .cbi-inner {
  opacity: 1;
  visibility: visible;
}
 */
