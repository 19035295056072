.banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-size: cover;
  background-position: center center;
  height: 297px;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.banner h1 {
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  .banner {
    height: 436px;
  }
}
